import Swiper from 'swiper';
//import { swiperCustomPagination } from '../../../assets/scripts/swiper-pagination';
(function ($) {
  const swiper = new Swiper('.video-swiper', {
    slidesPerView: 1.1,
    spaceBetween: 14,
    loop: true,
    simulateTouch: false,
    pagination: {
      el: '.video-swiper .swiper-pagination-bullets',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.video-swiper .swiper-button-next',
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  });

  if ($('#swiper-pagination-fraction-current').length > 0) {
    let slideNumber = swiper.realIndex + 1;
    const slideTotalNumber = swiper.slides.length - 2;

    function updatePagination() {
      document.getElementById('swiper-pagination-fraction-current').innerHTML =
        '0' + slideNumber;
      document.getElementById('swiper-pagination-fraction-total').innerHTML =
        '0' + slideTotalNumber;
    }
    updatePagination();

    swiper.on('slideChange', function () {
      slideNumber = swiper.realIndex + 1;
      updatePagination();
    });
  }
})(jQuery);

window.addEventListener('DOMContentLoaded', function () {
  function addEventOnPlay(item) {
    item.addEventListener('click', function () {
      const container = item.closest('.video-wrapper');

      if (container.querySelector('video')) {
        const video = container.querySelector('video');
        video.classList.add('video-is-playing');
        video.play();
      }

      if (container.querySelector('iframe')) {
        const video = container.querySelector('iframe');
        video.setAttribute('allow', 'autoplay');
        video.src = video.getAttribute('data-src');
        setTimeout(function () {
          video.classList.add('video-is-playing');
        }, 200);
      }
    });
  }

  function stopVideoPlaying() {
    const videosPlaying = document.querySelectorAll('.video-is-playing');
    videosPlaying.forEach((video) => {
      if ('IFRAME' === video.tagName) {
        video.src = '';
      }
      if ('video' === video.tagName) {
        video.stop();
      }
      video.classList.remove('video-is-playing');
    });
  }

  const playBtns = document.querySelectorAll('.play-video-btn');

  if (playBtns) {
    playBtns.forEach(addEventOnPlay);
  }

  const swiperVideo = document.querySelector('.video-swiper');

  if (swiperVideo) {
    swiperVideo
      .querySelector('.swiper-button-next')
      .addEventListener('click', function () {
        stopVideoPlaying();
      });
  }
});
